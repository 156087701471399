<script setup lang="ts">
import { useWindowScroll, watchDebounced, watchThrottled } from '@vueuse/core';
import { vElementVisibility } from '@vueuse/components';
import type { LinkNode } from '@laam/cms-shared';
import { EntityCard } from '@laam/ui/cms';

type Props = {
	dataSource: {
		dataSource: string;
		clickedFrom: string;
		version: string | undefined;
	};
	parentTitle: string;
	horizontalIndex: number;
	verticalIndex: number;
	entity: string;
	data: LinkNode;
};

const { $cathodeClient, $eventClient } = useNuxtApp();

const props = defineProps<Props>();

const { y } = useWindowScroll();
const hasWindowScrolled = ref(false);
watchThrottled(
	y,
	() => {
		hasWindowScrolled.value = true;
	},
	{ throttle: 200 },
);

function handleClickEvent() {
	$cathodeClient.queueEvent('entity_click', {
		source: window.location.href,
		page: 'homepage',
		identifier: props.data.handle!,
		clicked_from: props.dataSource.clickedFrom as 'homepage',
		entity: 'brand',
		horizontal_index: props.horizontalIndex,
		vertical_index: props.verticalIndex,
		slotted: props.data.slotted ?? false,
		heading: props.parentTitle,
		component: 'node-x-brands',
		product_count: props.data.product_count!,
		sale_percentage: props.data.max_sale!,
		scroll_position: y.value,
		data_source: `${props.dataSource.dataSource} - ${props.dataSource.version}`,
		data_string: JSON.stringify(props.data),
	});
	$eventClient.sendEvent('homepage-carousel-click', {
		to: props.data.href,
		index: props.verticalIndex,
		title: props.parentTitle,
	});
}

const isVisible = ref(false);
function onElementVisibility(state: boolean) {
	isVisible.value = state;
}

watchDebounced(
	isVisible,
	(value) => {
		if (value) {
			$cathodeClient.queueImpression('entity_impression', {
				data: [
					{
						identifier: props.data.handle,
						product_count: props.data.product_count,
						sale_percentage: props.data.max_sale,
						slotted: props.data.slotted,
						horizontal_index: props.horizontalIndex,
						data_string: JSON.stringify(props.data),
					},
				],
				vertical_index: props.verticalIndex,
				entity: props.entity as 'brand',
				heading: props.parentTitle,
				component: props.entity === 'brand' ? 'node-x-brands' : 'node-x-drops',
				scroll_position: y.value,
				source: window.location.href,
				type: hasWindowScrolled.value ? 'scroll_impression' : 'load_impression',
				time: new Date().toUTCString(),
				data_source: `${props.dataSource.dataSource} - ${props.dataSource.version}`,
				clicked_from: 'homepage',
				page: 'homepage',
			});
		}
	},
	{
		debounce: useRuntimeConfig().public.impressionDebounceTime,
	},
);
</script>

<template>
	<NuxtLink :to="data.href" @click="handleClickEvent()">
		<EntityCard
			v-element-visibility="[onElementVisibility, { threshold: 0.8 }]"
			:data="data"
			:entity="entity"
			:index="horizontalIndex"
		/>
	</NuxtLink>
</template>
