<script setup lang="ts">
import {
	Button,
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
	ProductCardSkeleton,
} from '@laam/ui/base';

import ProductCard from '../../ProductCard.vue';
import type {
	CategoriesResponse,
	DropsResponse,
	EntityXProductData,
	LinkNode,
} from '@laam/cms-shared';
import { computed, provide, ref } from 'vue';
import { useEntityProducts } from '~/data/products';

const { data, metadata } = defineProps<{
	metadata: EntityXProductData;
	data: DropsResponse | CategoriesResponse;
	sectionIndex: number;
}>();

const getFormattedPrice = computed(() => {
	return (price: number) => {
		return `${price.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
	};
});

provide('getFormattedPrice', getFormattedPrice);
provide('currencySymbol', 'PKR');

const Logan = useLoganStore();

const title = ref<HTMLSpanElement | null>(null);

const output = computed<LinkNode[]>(() => {
	return data.map((item) => ({
		id: 'drop_id' in item ? item.drop_id : item.id,
		name: item.title.replace(/__/g, ''),
		entity: 'drop_id' in item ? 'drops' : 'nodes',
		href:
			'drop_id' in item
				? '/drops/' + item.handle
				: '/nodes/' +
					item.title.toLowerCase().replace(/ /g, '-') +
					'-' +
					item.id,
		handle: 'drop_id' in item ? item.handle : undefined,
		image: item.v24_top_product_image,
	}));
});

const selectedTab = ref(output.value[0]!.id);
const selectedTabHandle = ref(output.value[0]!.handle);

watch(selectedTab, (newVal) => {
	const tab = output.value.find((tab) => tab.id === newVal);
	if (tab) {
		selectedTabHandle.value = tab.handle;
	}
	isCollapsibleOpen.value = false;
});

const { data: page, isLoading } = useEntityProducts(
	metadata.entity,
	metadata.entity === 'nodes' ? selectedTab : ref(undefined),
	selectedTabHandle,
);

const isCollapsibleOpen = ref(false);

const deviceType = inject('deviceType') as Ref<string | undefined>;

const itemsToSlice = computed(() => {
	if (deviceType.value === 'mobile') {
		return 6;
	} else {
		return 8;
	}
});

const redirectUrl = computed(() => {
	const tab = output.value.find((tab) => tab.id === selectedTab.value);
	if (!tab) return '';
	return tab.href;
});
</script>

<template>
	<div class="gap-3xl lg:gap-7xl p-3xl lg:py-8xl flex flex-col items-center">
		<div ref="title" class="gap-sm flex flex-col items-center">
			<span class="lg:display-xs text-xl font-semibold text-gray-900">
				{{ metadata.title }}
			</span>
		</div>

		<div
			class="entity-x-products-grid--body py-md gap-3xl flex items-center justify-center w-full"
		>
			<Tabs
				v-model:model-value="selectedTab"
				class="gap-7xl flex flex-col w-full"
			>
				<div class="flex justify-center w-full">
					<TabsList
						class="tabs-list px-3xl gap-3xl w-fit no-scrollbar flex overflow-auto"
					>
						<div v-for="(tabItem, idx) in output" :key="idx">
							<TabsTrigger :value="tabItem.id" class="w-fit whitespace-nowrap">
								{{ tabItem.name }}
							</TabsTrigger>
						</div>
					</TabsList>
				</div>

				<TabsContent :value="selectedTab">
					<div
						v-if="!isLoading"
						class="gap-3xl lg:gap-7xl lg:grid-cols-4 grid w-full grid-cols-2"
					>
						<!-- TODO: figure out how to call from products here -->
						<div
							v-for="(item, horizontalIndex) in (page?.products || []).slice(
								0,
								itemsToSlice,
							)"
							:key="horizontalIndex"
							class="w-full"
						>
							<slot :horizontal-index="horizontalIndex" :product="item">
								<ProductCard
									:data="item"
									:index="horizontalIndex"
									:data-source="{
										dataSource: 'valhalla',
										clickedFrom: ['homepage'],
										version: 'v0',
									}"
									page="homepage"
									:horizontal-index="horizontalIndex"
									:vertical-index="sectionIndex"
									:is-hvc="Logan.isHvc"
								/>
							</slot>
						</div>
					</div>
					<div v-else>
						<div
							class="gap-3xl lg:grid-cols-12 lg:grid-rows-2 grid w-full grid-cols-2 grid-rows-3"
						>
							<div
								v-for="(_, horizontalIndex) in [...Array(8).keys()]"
								:key="horizontalIndex"
								class="lg:col-span-3 w-full col-span-1"
							>
								<ProductCardSkeleton />
							</div>
						</div>
					</div>
				</TabsContent>
			</Tabs>
		</div>
		<NuxtLink :to="redirectUrl" class="lg:w-fit w-full mx-auto">
			<Button
				variant="secondary"
				size="md"
				class="!py-md w-full lg:!w-fit !bg-white"
			>
				View All
			</Button>
		</NuxtLink>
	</div>
</template>

<style>
.drop-tabs--carousel .carousel__prev,
.drop-tabs--carousel .carousel__next {
	@apply bg-white rounded-full p-md mx-3xl lg:p-lg lg:mx-5xl;
}
.drop-tabs--carousel .drop-tabs--carousel__item {
	@apply relative rounded-xs text-xl text-white bg-white w-full;
}

.drop-tabs--carousel .carousel__slide {
	@apply p-none items-start;
}

.drop-tabs--carousel .carousel__pagination {
	@apply absolute mx-auto left-[0px] right-[0px] gap-sm w-fit bottom-3xl;
}

.drop-tabs--carousel .carousel__pagination-item {
	@apply box-border;
}

.drop-tabs--carousel .carousel__pagination-button {
	@apply p-none;
}

.drop-tabs--carousel .carousel__pagination-button::after {
	@apply bg-white rounded-2xl w-10xl h-xs opacity-40;
}

.drop-tabs--carousel .carousel__pagination-button--active::after {
	@apply bg-white opacity-100;
}
</style>
