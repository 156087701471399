<script setup lang="ts">
import {
	Button,
	ProductCardSkeleton,
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from '@laam/ui/base';

import {
	type LinkNode,
	type CategoriesResponse,
	type DropsResponse,
	type EntityXProductData,
} from '@laam/cms-shared';
import { computed, provide, ref } from 'vue';
import { useEntityProducts } from '~/data/products';
import ProductCard from '../../ProductCard.vue';

interface Props {
	metadata: EntityXProductData;
	data: DropsResponse | CategoriesResponse;
	sectionIndex: number;
}

const { data, metadata } = defineProps<Props>();
const getFormattedPrice = computed(() => {
	return (price: number) => {
		return `${price.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
	};
});

const output = computed<LinkNode[]>(() => {
	return (
		data?.map((item) => ({
			id: 'drop_id' in item ? item.drop_id : item.id,
			name: item.title.replace(/__/g, ''),
			entity: 'drop_id' in item ? 'drops' : 'nodes',
			href:
				'drop_id' in item
					? '/drops/' + item.handle
					: '/nodes/' +
						item.title.toLowerCase().replace(/ /g, '-') +
						'-' +
						item.id,
			image: item.v24_top_product_image,
			handle: 'drop_id' in item ? item.handle : undefined,
		})) || []
	);
});

provide('getFormattedPrice', getFormattedPrice);
provide('currencySymbol', 'PKR');

const selectedTab = ref(output.value[0]!.id);
const selectedTabHandle = ref(output.value[0]!.handle);

const Logan = useLoganStore();

watch(
	selectedTab,
	(newVal) => {
		const tab = output.value.find((tab) => tab.id === newVal);
		if (tab) {
			selectedTabHandle.value = tab.handle;
		}
	},
	{ immediate: true },
);

const { data: page, isLoading } = useEntityProducts(
	metadata.entity,
	metadata.entity === 'nodes' ? selectedTab : ref(undefined),
	selectedTabHandle,
);

const redirectUrl = computed(() => {
	const tab = output.value.find((tab) => tab.id === selectedTab.value);
	if (!tab) return '';
	return tab.href;
});

const { deviceType } = useDeviceType();
</script>

<template>
	<div class="gap-3xl lg:gap-7xl p-3xl md:py-8xl flex flex-col items-center">
		<div class="gap-sm flex flex-col items-center">
			<span class="text-lg font-semibold lg:display-xs text-gray-900">
				{{ metadata.title }}
			</span>
		</div>

		<div
			class="entity-x-products-carousel--body py-md gap-3xl flex items-center justify-center w-full"
		>
			<Tabs
				v-model:model-value="selectedTab"
				class="gap-7xl flex flex-col w-full"
			>
				<div class="flex justify-center w-full">
					<TabsList
						class="tabs-list px-3xl gap-3xl w-fit flex overflow-auto no-scrollbar"
					>
						<div v-for="tabItem in output" :key="tabItem.id">
							<TabsTrigger :value="tabItem.id" class="w-fit whitespace-nowrap">
								{{ tabItem.name }}
							</TabsTrigger>
						</div>
					</TabsList>
				</div>

				<TabsContent :value="selectedTab">
					<Carousel
						v-if="!isLoading"
						class="drop-tabs--carousel w-full"
						:items-to-show="deviceType === 'mobile' ? 2.2 : 4"
						snap-align="start"
					>
						<Slide
							v-for="(item, horizontalIndex) in page?.products"
							:key="horizontalIndex"
							class="drop-tabs--carousel__item"
							:index="horizontalIndex"
						>
							<div class="mr-3xl lg:mr-7xl w-full overflow-hidden">
								<slot :product="item" :horizontal-index="horizontalIndex">
									<ProductCard
										:data="item"
										:index="horizontalIndex"
										:data-source="{
											dataSource: 'valhalla',
											clickedFrom: ['homepage'],
											version: 'v0',
										}"
										page="homepage"
										:horizontal-index="horizontalIndex"
										:vertical-index="sectionIndex"
										:is-hvc="Logan.isHvc"
									/>
								</slot>
							</div>
						</Slide>

						<template #addons>
							<Navigation
								v-if="
									page && page.products.length > 3 && deviceType !== 'mobile'
								"
							/>
						</template>
					</Carousel>
					<Carousel
						v-else
						class="w-full"
						:items-to-show="deviceType === 'mobile' ? 2.2 : 4"
						snap-align="start"
					>
						<Slide v-for="i in [0, 1, 2, 3]" :key="i">
							<ProductCardSkeleton />
						</Slide>
					</Carousel>
				</TabsContent>
			</Tabs>
		</div>

		<NuxtLink :to="redirectUrl" class="w-full lg:w-fit mx-auto">
			<Button variant="secondary" size="lg" class="w-full lg:w-fit mx-auto">
				<span class="px-xxs">View All</span>
			</Button>
		</NuxtLink>
	</div>
</template>

<style>
.drop-tabs--carousel .carousel__prev,
.drop-tabs--carousel .carousel__next {
	@apply bg-white rounded-full p-md mx-3xl lg:p-lg lg:mx-5xl;
}
.drop-tabs--carousel .drop-tabs--carousel__item {
	@apply relative rounded-xs text-xl text-white bg-white w-full;
}

.drop-tabs--carousel .carousel__slide {
	@apply p-none items-start;
}

.drop-tabs--carousel .carousel__pagination {
	@apply absolute mx-auto left-[0px] right-[0px] gap-sm w-fit bottom-3xl;
}

.drop-tabs--carousel .carousel__pagination-item {
	@apply box-border;
}

.drop-tabs--carousel .carousel__pagination-button {
	@apply p-none;
}

.drop-tabs--carousel .carousel__pagination-button::after {
	@apply bg-white rounded-2xl w-10xl h-xs opacity-40;
}

.drop-tabs--carousel .carousel__pagination-button--active::after {
	@apply bg-white opacity-100;
}
</style>
