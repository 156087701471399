<script setup lang="ts">
import { PhHeartStraight } from '@phosphor-icons/vue';
import { ProductCard } from '@laam/ui/base';

import type { ProductsItem } from '@laam/cms-shared';

import { computed, provide } from 'vue';

interface ProductCardData {
	data: ProductsItem;
	index: number;
}

defineProps<ProductCardData>();

const getFormattedPrice = computed(() => {
	return (price: number) => {
		return `${Math.round(price).toLocaleString()}`;
	};
});

provide('getFormattedPrice', getFormattedPrice);
provide('currencySymbol', 'PKR');
</script>
<template>
	<div class="lg:px-3xl px-md gap-md flex flex-col items-start w-full">
		<ProductCard
			page="PLP"
			:shopify-id="data.shopify_id"
			:handle="data.handle"
			:image-url="data.image.src"
			:image-alt="data.image.alt"
			:variants="data.variants"
			:brand-name="data.brand"
			:collection-name="data.title ?? ''"
			:tags="data.tags"
			:inventory-quantity="data.total_inventory_quantity"
			:is-hvc="false"
		>
			<template #image>
				<img
					:src="data.image.src"
					alt="data.image.alt"
					class="rounded-small aspect-[5/7] object-contain"
				/>
			</template>

			<template #top-right>
				<PhHeartStraight
					size="24"
					:weight="'duotone'"
					class="fill-black [&>g>path:nth-last-child(1)]:fill-white"
				/>
			</template>

			<template #bottom-right>
				<div></div>
			</template>
		</ProductCard>
	</div>
</template>
