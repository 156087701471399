<script setup lang="ts">
import type { ProductsData, ProductsResponseSchema } from '@laam/cms-shared';
import ProductCard from './ProductCard.vue';
import Button from '../base/button/Button.vue';
import { computed, inject, ref, type Ref } from 'vue';
import { Collapsible } from '../base';
import CollapsibleContent from '../base/collapsible/CollapsibleContent.vue';
import CollapsibleTrigger from '../base/collapsible/CollapsibleTrigger.vue';

interface Props {
	metadata: ProductsData;
	data: ProductsResponseSchema;
	sectionIndex: number;
}
defineProps<Props>();

const isOpen = ref(false);

const deviceType = inject('deviceType') as Ref<string | undefined>;

const itemsToSlice = computed(() => {
	if (deviceType.value === 'mobile') {
		return 6;
	} else {
		return 8;
	}
});
</script>

<template>
	<div
		class="products-grid--body gap-3xl lg:py-8xl px-3xl lg:px-0 flex flex-col items-center p-0"
	>
		<div class="gap-sm flex flex-col items-center w-full">
			<span class="text-lg lg:display-xs font-semibold text-gray-900">
				{{ metadata.title }}
			</span>
		</div>

		<Collapsible v-model:open="isOpen" class="w-full">
			<div
				class="lg:gap-y-8xl lg:gap-x-7xl gap-3xl lg:grid-cols-4 grid w-full grid-cols-2"
			>
				<div
					v-for="(product, horizontalIndex) in data.products.slice(
						0,
						itemsToSlice,
					)"
					:key="horizontalIndex"
					class="w-full"
				>
					<slot :horizontal-index="horizontalIndex" :product="product">
						<ProductCard :index="horizontalIndex" :data="product" />
					</slot>
				</div>
			</div>

			<CollapsibleContent
				class="lg:gap-7xl gap-3xl lg:grid-cols-4 grid w-full grid-cols-2 mt-3xl lg:mt-7xl"
			>
				<div
					v-for="(product, horizontalIndex) in data.products.slice(
						itemsToSlice,
						20,
					)"
					:key="horizontalIndex"
					class="w-full"
				>
					<slot :horizontal-index="horizontalIndex" :product="product">
						<ProductCard :index="horizontalIndex" :data="product" />
					</slot>
				</div>
			</CollapsibleContent>
			<div class="w-full">
				<CollapsibleTrigger :as-child="true" class="w-full">
					<Button
						v-if="data.products.length > itemsToSlice"
						variant="secondary"
						size="lg"
						class="lg:w-fit mx-auto w-full"
					>
						<span class="px-xxs">{{ isOpen ? 'View Less' : 'View More' }}</span>
					</Button>
				</CollapsibleTrigger>
			</div>
		</Collapsible>
	</div>
</template>
