<!-- 
	Widths & Heights should only be set for images that require
	these dimensions to be set e.g. avatars or icons.

	Use sizes attribute for responsive images
-->
<script setup lang="ts">
import { cn } from '@laam/lib/utils';
import { ref } from 'vue';

interface LazyImageProps {
	src: string;
	alt: string;
	width: string | number;
	loading?: 'lazy' | 'eager';
	class?: string;
}

const MAX_RETRIES = 1;
const retryCount = ref(0);

const props = withDefaults(defineProps<LazyImageProps>(), {
	width: '150',
	loading: 'lazy',
	class: '',
});
const currentSrc = ref(props.src);

const retryLoad = () => {
	if (retryCount.value < MAX_RETRIES) {
		retryCount.value++;

		// Force browser to make a new request by adding timestamp
		currentSrc.value = `${props.src}${props.src.includes('?') ? '&' : '?'}_retry=${Date.now()}`;
	}
};
</script>
<template>
	<NuxtImg
		:key="currentSrc"
		:class="cn('w-full lazy_image', props.class)"
		provider="shopify"
		v-bind="props"
		:loading="loading"
		@error="retryLoad"
	/>
</template>
