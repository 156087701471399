<script setup lang="ts">
import { type EntityData, type LinkNode } from '@laam/cms-shared';
import CategoryCard from './CategoryCard.vue';
import { inject, ref, type Ref } from 'vue';

interface Props {
	metadata: EntityData;
	data: LinkNode[];
	sectionIndex: number;
}

const { data, metadata } = defineProps<Props>();

const deviceType = inject('deviceType') as Ref<string | undefined>;

const categoryCarouselRef = ref();
</script>

<template>
	<div class="gap-3xl lg:gap-7xl lg:px-0 px-3xl flex flex-col items-center">
		<div
			class="gap-sm flex flex-col w-full"
			:class="{
				'items-center': metadata.alignment === 'center',
				'items-start': metadata.alignment === 'left',
			}"
		>
			<h1 ref="title" class="text-lg font-semibold lg:display-xs text-gray-900">
				{{ metadata.title }}
			</h1>
		</div>

		<Carousel
			ref="categoryCarouselRef"
			class="cms--carousel-categories w-full"
			:items-to-show="deviceType === 'mobile' ? 2.2 : 4"
			snap-align="start"
		>
			<Slide
				v-for="(card, horizontalIndex) in data"
				:key="horizontalIndex"
				:index="horizontalIndex"
			>
				<slot :entity="metadata.entity" :data="card" :index="horizontalIndex">
					<CategoryCard
						:entity="metadata.entity"
						:data="card"
						:index="horizontalIndex"
						shape="rectangle"
					/>
				</slot>
			</Slide>

			<template #addons>
				<Navigation v-if="data.length > 4 && deviceType !== 'mobile'" />
			</template>
		</Carousel>
	</div>
</template>

<style>
.cms--carousel-categories .carousel__prev {
	@apply bg-white rounded-full top-[49%] p-md mx-3xl lg:p-lg lg:mx-5xl;
}

.cms--carousel-categories .carousel__next {
	@apply bg-white rounded-full top-[49%] p-md mx-3xl lg:p-lg lg:ml-5xl lg:mr-9xl;
}

.cms--carousel-categories .cms--carousel-categories__item {
	@apply relative rounded-xs text-xl text-white bg-white w-full;
}

.cms--carousel-categories .carousel__slide {
	@apply pr-3xl lg:pr-7xl items-start;
}

.cms--carousel-categories .carousel__pagination {
	@apply absolute mx-auto left-[0px] right-[0px] gap-sm w-fit bottom-3xl;
}

.cms--carousel-categories .carousel__pagination-item {
	@apply box-border;
}

.cms--carousel-categories .carousel__pagination-button {
	@apply p-none;
}

.cms--carousel-categories .carousel__pagination-button::after {
	@apply bg-white rounded-2xl w-10xl h-xs opacity-40;
}

.cms--carousel-categories .carousel__pagination-button--active::after {
	@apply bg-white opacity-100;
}
</style>
