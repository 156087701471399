<script setup lang="ts">
import { useDeviceType } from '~/utils/use-device-type';
import LazyImage from '~/components/LazyImage.vue';
import { useWindowScroll, watchDebounced, watchOnce } from '@vueuse/core';
import { vElementVisibility } from '@vueuse/components';
import type { HeroBannerItem } from '@laam/cms-shared';

const { deviceType } = useDeviceType();
const { $eventClient, $cathodeClient } = useNuxtApp();

interface SliderImageComponent {
	data: HeroBannerItem;
	verticalIndex: number;
	horizontalIndex: number;
}

const entity = ref('');

const collectionToNode = (url: string) => {
	if (url.includes('collections')) {
		entity.value = 'node';
		return '/nodes' + url.split('collections')[1];
	} else {
		if (url.includes('brands')) {
			entity.value = 'brand';
		} else if (url.includes('drops')) {
			entity.value = 'drop';
		} else if (url.includes('nodes')) {
			entity.value = 'node';
		}
		return url;
	}
};

const props = defineProps<SliderImageComponent>();

const { y } = useWindowScroll();
const hasWindowScrolled = ref(false);
watchOnce(y, () => {
	hasWindowScrolled.value = true;
});
const isVisible = ref(false);

function onElementVisibility(state: boolean) {
	isVisible.value = state;
}

watchDebounced(
	isVisible,
	(value) => {
		if (value) {
			$cathodeClient.queueImpression('entity_impression', {
				data: [
					{
						identifier: props.data.link.href.split('/')[2],
						data_string: JSON.stringify(props.data),
					},
				],
				vertical_index: props.verticalIndex,
				entity: entity.value as 'node' | 'brand' | 'drop',
				heading: '',
				component: 'image-slider',
				scroll_position: y.value,
				source: window.location.href,
				type: hasWindowScrolled.value ? 'scroll_impression' : 'load_impression',
				time: new Date().toUTCString(),
				data_source: 'manual',
				clicked_from: 'homepage',
				page: 'homepage',
			});
		}
	},
	{
		debounce: useRuntimeConfig().public.impressionDebounceTime,
	},
);
function handleClickEvent() {
	// Cathode event
	$cathodeClient.queueEvent('entity_click', {
		source: window.location.href,
		page: 'homepage',
		identifier: props.data.link.href.split('/')[2]!,
		clicked_from: 'homepage',
		entity: entity.value as 'node' | 'brand' | 'drop',
		horizontal_index: props.horizontalIndex,
		vertical_index: props.verticalIndex,
		slotted: false,
		heading: '',
		component: 'image-slider',
		product_count: null,
		sale_percentage: 0,
		scroll_position: y.value,
		data_source: 'manual',
		data_string: JSON.stringify(props.data),
	});
	// statsig event
	$eventClient.sendEvent('homepage-carousel-click', {
		to: props.data.link.href,
		index: 2,
		title: 'hero banner',
		horizontalIndex: props.horizontalIndex,
	});
}

const imageUrl = computed(() => {
	return deviceType.value === 'desktop'
		? props.data.desktop_url
		: props.data.mobile_url;
});
</script>
<template>
	<NuxtLink
		v-element-visibility="[onElementVisibility, { threshold: 0.8 }]"
		:to="collectionToNode(props.data.link.href)"
		class="block w-full lg:rounded-3xl"
		@click="handleClickEvent()"
	>
		<LazyImage
			class="homepage__image-slider-image lg:rounded-3xl"
			:src="imageUrl"
			:alt="imageUrl"
			:width="deviceType === 'mobile' ? 250 : 1032"
			loading="eager"
		/>
	</NuxtLink>
</template>
